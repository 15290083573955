import React from "react";
import PostImage from "./post-image";

const BlogCard = ({ post }) => {
  
  return (
    <>
       <a 
        href={`/blog/${post.slug}`}
        className="w-full block relative overflow-hidden aspect-w-4 aspect-h-3 hover:opacity-80 bg-cx">
        <div className="absolute inset-0">
          {post.image && <PostImage post={post} />}
        </div>
      </a> 
      <div className="font-medium text-grey-400 flex flex-wrap tracking-wide  mt-5 mb-3 -mx-2">
        {post.tags &&
          post.tags.map(tag => {
            return (
              <div className="px-2 w-auto"><a className="uppercase text-xs md:text-sm opacity-60 hover:opacity-100" href={`/blog/tag/${tag.slug}`}>{tag.title}</a></div>
            )
          })
        }

      </div>
      <a 
        href={`/blog/${post.slug}`}
        className="hover:opacity-80 block"
      >
        <p className="text-white text-xl font-semibold font-medium mt-4 mb-3">{post.heading}</p>
        <p className="font-medium text-blue">
          Read post
          {" "}
          →
        </p>
      </a>         
    </>
  )
}

export default BlogCard