import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const PostImage = ({ post }) => {

  const image = getImage(post.image)

  const { height, width } = post.image.file.details.image

  const aspectRatio = height / width;

  return (
    <GatsbyImage 
      className={`w-full h-full`} 
      alt={post.heading} 
      image={image} 
      objectFit={`${aspectRatio >= 0.8 ? 'contain' : 'cover'}`}
    />
  )
}

export default PostImage;