

import React from "react";
import ContactUsForm from "./contact-form";
import { renderRTF } from "../rich-text";
import getSpacing from "../../utils/getSpacing";
import { Fade } from "react-awesome-reveal";

const SectionContact = ({ data }) => {

  const spacing = getSpacing(data.topSpacing, data.bottomSpacing)

  return (
    <section className={`bg-black text-white ${spacing} md:bg-[url('/cx.svg')] bg-no-repeat bg-left-bottom bg-[length:600px]`} id="contact">
      <div className="container">
        <div className="flex flex-wrap justify-center lg:justify-between items-center -mx-4">
          <div className="px-4 w-full md:w-7/12 lg:w-5/12">
            
            <Fade direction="up">
              <div className="text-center lg:text-left mb-6 lg:mb-10">
                {renderRTF(data.text)}
              </div>
            </Fade>
            
          </div>
          <div className="px-4 w-full lg:w-1/2">
            <ContactUsForm textColor={`text-secondary`} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionContact